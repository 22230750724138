<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="pf-video">
      <video id="pfVideo" class="video" muted autoplay loop playsinline>
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegram" @click="toTg"></div>
      <div class="webIcon" @click="toBusiness"></div>
    </div>
    <div class="logoText">
      <div class="time">{{ time }}</div>
    </div>
    <div class="downloadBox">
      <div class="qrcodeBorder">
        <img class="qrcodeBox" :src="qrImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {
      time: "",
      videoSrc: require("@/assets/video/video.mp4"),
    };
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  created() {
    setInterval(() => {
      this.time = moment().format("HH:mm");
    }, 1000);
  },
  methods: {
    toBusiness() {
      window.open("https://t.me/laiango2naduo");
    },
    toTg() {
      window.open("https://t.me/BabyBusfensiqun");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  // background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  // background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .pf-video {
    position: absolute;
    width: 100%;
    height: 100%;
    .video {
      width: 100%;
    }
  }
  .logo {
    position: fixed;
    left: 40px;
    top: 40px;
    height: 103px;
    width: 334px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    right: 40px;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .webIcon {
      height: 89px;
      width: 77px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .telegram {
      height: 89px;
      width: 77px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .logoText {
    height: 356px;
    width: 379px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 231px;
    left: 50%;
    transform: translate(-50%, 0);
    .time {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 120px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: absolute;
      left: 50%;
      bottom: 55px;
      transform: translate(-50%, 0);
    }
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 60px;
    bottom: 60px;
    .qrcodeBorder {
      height: 190px;
      width: 190px;
      background-color: rgba(0, 204, 255, 1);
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid rgba(0, 204, 255, 1);
      box-shadow: 0 0 30px rgba(0, 204, 255, 1);
      .qrcodeBox {
        height: 186px;
        width: 186px;
        background-color: white;
      }
    }
    .installSuggestion {
      height: 250px;
      width: 486px;
      background: url("./../../../assets/images/pc/installSuggestion.webp")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
